import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendOTP = createAsyncThunk(
  'global/sendOTP',
  async ({ phoneNumber, showToast }, thunkAPI) => {
    try {
      const res = await axios.post(
        'https://prod.ratham.in/auth-service/public/v1/auth/otp/send',
        { phoneNumber },
        {
          headers: {
            Authorization: 'Bearer 3gf8er7g87b87gdka32gj09',
          },
        }
      );

      showToast('OTP sent successfully!', 'success');

      return res.data;
    } catch (err) {
      showToast(err.response.data.errorOut.message, 'error');
    }
  }
);

export const verifyOTP = createAsyncThunk(
  'global/verifyOTP',
  async ({ otpRef, otpValue, showToast }, thunkAPI) => {
    try {
      const res = await axios.post(
        'https://prod.ratham.in/auth-service/public/v1/auth/otp/verify',
        { otpRef, otpValue }
      );

      return res.data;
    } catch (err) {
      showToast(err.response.data.errorOut.message, 'error');
    }
  }
);

export const getUserData = createAsyncThunk(
  'global/getUserData',
  async ({ accessToken, showToast }, thunkAPI) => {
    try {
      const res = await axios.get(
        'https://prod.ratham.in/user-service/public/v1/users',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return res.data;
    } catch (err) {
      showToast(err.response.data.errorOut.message, 'error');
    }
  }
);
