import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import globalReducer from './slices/globalSlice';

const reducer = combineReducers({
  global: globalReducer,
});

const store = configureStore({
  reducer,
});

export default store;
