import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Login } from './pages/global';
import { TADashboard } from './pages/trips';
import Poc from './pages/gmappoc/Poc';

function App() {
  const { currentUI } = useSelector((state) => state.global);

  return (
    <div className="App">
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              index
              element={currentUI === 'login' ? <Login /> : <TADashboard />}
            />
          </Route>
          <Route path="poc-maps">
            <Route index element={<Poc />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
