import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUI: 'login',
  accessToken: '',
  userData: {
    phoneNumber: '',
    name: '',
    role: 0,
    userId: '',
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setCurrentUI: (state, action) => {
      state.currentUI = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.userData.phoneNumber = action.payload;
    },
    setUserData: (state, action) => {
      const { name, role, userId } = action.payload;
      state.userData.name = name;
      state.userData.role = role;
      state.userData.userId = userId;
    },
  },
});

export const { setCurrentUI, setAccessToken, setPhoneNumber, setUserData } =
  globalSlice.actions;

export default globalSlice.reducer;
