import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../hooks';
import { getUserData } from '../../redux/actions/globalActions';
import { setUserData } from '../../redux/slices/globalSlice';

function TADashboard() {
  const { showToast } = useToast();

  const dispatch = useDispatch();

  const { accessToken, userData } = useSelector((state) => state.global);

  const fetchUserData = () => {
    dispatch(getUserData({ accessToken, showToast }))
      .then((res) => {
        const { userData } = res.payload;
        const { name, role, userId } = userData;
        dispatch(setUserData({ name, role, userId }));
      })
      .catch((err) => showToast(err, 'error'));
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div>
      <p>Welcome {userData.name}!</p>
    </div>
  );
}

export default TADashboard;
