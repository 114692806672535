import { useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { formatDistance, formatDuration } from './utils';

const center = { lat: 17.4528837, lng: 78.3877043 };

function Poc1() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['places'],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [summary, setSummary] = useState('');
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [waypoints, setWaypoints] = useState([]);

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const waypointRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  if (!isLoaded) {
    return <div className="p-4">Loading...</div>;
  }

  async function calculateRoute() {
    if (originRef.current.value === '' || destiantionRef.current.value === '') {
      return;
    }
    waypointRef.current.value = '';
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destiantionRef.current.value,
      waypoints: waypoints
        .filter((e) => e.active)
        .map((e) => Object({ location: e.location, stopover: true })),
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setSummary(results.routes[0].summary);

    let totalDistance = 0;
    let totalDuration = 0;
    results.routes[0].legs.forEach((leg) => {
      totalDistance += leg.distance.value; // in meters
      totalDuration += leg.duration.value; // in seconds
    });

    const formattedDistance = formatDistance(totalDistance);
    const formattedDuration = formatDuration(totalDuration);

    setDistance(formattedDistance);
    setDuration(formattedDuration);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setSummary('');
    setDistance('');
    setDuration('');
    setWaypoints([]);
    originRef.current.value = '';
    waypointRef.current.value = '';
    destiantionRef.current.value = '';
    map.panTo(center);
    map.setZoom(15);
  }

  const handleWaypoint = (idx, action) => {
    if (action === 'change') {
      let copyWaypoints = JSON.parse(JSON.stringify(waypoints));
      copyWaypoints = copyWaypoints.map((each, currIdx) => {
        if (currIdx === idx) {
          each.active = !each.active;
        }
        return each;
      });
      setWaypoints(copyWaypoints);
      return;
    }

    let copyWaypoints = JSON.parse(JSON.stringify(waypoints));
    copyWaypoints = copyWaypoints.filter((_, currIdx) => currIdx !== idx);
    setWaypoints(copyWaypoints);
  };

  return (
    <div className="flex" style={{ height: '85vh' }}>
      <div className="p-4 w-80 ml-1">
        <div>
          <Autocomplete>
            <input
              type="text"
              placeholder="Origin"
              ref={originRef}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </Autocomplete>
        </div>
        <div className="mt-4">
          <Autocomplete
            onPlaceChanged={() => {
              setWaypoints([
                ...waypoints,
                {
                  location: waypointRef.current.value,
                  active: true,
                  stopover: true,
                },
              ]);
            }}
          >
            <input
              type="text"
              placeholder="Add Waypoint"
              ref={waypointRef}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </Autocomplete>
        </div>
        <div className="mt-4">
          <Autocomplete>
            <input
              type="text"
              placeholder="Destination"
              ref={destiantionRef}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </Autocomplete>
        </div>

        <div className="mt-4 space-x-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            type="submit"
            onClick={calculateRoute}
          >
            Show me Route
          </button>
          <button
            type="button"
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={clearRoute}
          >
            <FaTimes />
          </button>
        </div>

        <div className="mt-4">
          {summary && <div className="font-bold">via {summary}</div>}
          <div>Total Distance: {distance}</div>
          <div>Total Duration: {duration}</div>
        </div>

        <div className="mt-4 space-y-2">
          {waypoints.map((e, idx) => (
            <div key={e.location} className="flex items-center">
              <input
                type="checkbox"
                defaultChecked={e.active}
                checked={e.active}
                onChange={() => handleWaypoint(idx, 'change')}
                className="mr-2"
              />
              <div>{e.location}</div>
              <button
                type="button"
                className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                onClick={() => handleWaypoint(idx, 'delete')}
              >
                <FaTimes />
              </button>
            </div>
          ))}
        </div>
      </div>

      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          marginRight: '5px',
        }}
        options={{
          zoomControl: true,
          streetViewControl: true,
          mapTypeControl: false,
          fullscreenControl: true,
        }}
        onLoad={(map) => setMap(map)}
      >
        <Marker position={center} />
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
      </GoogleMap>
    </div>
  );
}

export default Poc1;
