export function formatDistance(distanceValue) {
  // Convert distance from meters to kilometers and round to two decimal places
  const distanceKm = (distanceValue / 1000).toFixed(2);
  return `${distanceKm} km`;
}

export function formatDuration(durationValue) {
  // Convert duration from seconds to hours and minutes
  const hours = Math.floor(durationValue / 3600);
  const minutes = Math.floor((durationValue % 3600) / 60);

  // Format the result as "hours mins"
  const formattedDuration = `${hours} hours ${minutes} mins`;
  return formattedDuration;
}
