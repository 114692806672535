import { useState } from 'react';
import Poc1 from './Poc1';
import Poc2 from './Poc2';
import AddEmpToRoute from './AddEmpToRoute';

const POCs = {
  poc1: [<Poc1 />, 'POC 1'],
  poc2: [<Poc2 />, 'POC 2'],
  poc3: [<AddEmpToRoute />, 'POC 3'],
};

export default function Poc() {
  const [currentUI, setCurrentUI] = useState(null);

  if (currentUI)
    return (
      <>
        <div className="flex justify-center gap-4 m-4 items-center">
          <div className="font-bold italic">
            {`Google Maps ${POCs[currentUI][1]}`}
          </div>
          <button
            type="button"
            className="bg-teal-500 text-white px-4 py-2 rounded"
            onClick={() => setCurrentUI(null)}
          >
            Exit
          </button>
        </div>
        {POCs[currentUI][0]}
      </>
    );

  return (
    <div className="m-8 space-x-8">
      <button
        type="button"
        className="bg-pink-500 text-white px-4 py-2 rounded"
        onClick={() => setCurrentUI('poc1')}
      >
        POC 1
      </button>
      <button
        type="button"
        className="bg-green-500 text-white px-4 py-2 rounded"
        onClick={() => setCurrentUI('poc2')}
      >
        POC 2
      </button>
      <button
        type="button"
        className="bg-purple-500 text-white px-4 py-2 rounded"
        onClick={() => setCurrentUI('poc3')}
      >
        POC 3
      </button>
    </div>
  );
}
