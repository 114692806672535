import { useEffect, useState } from 'react';
import { MdOutlineGroup } from 'react-icons/md';
import './AddEmpToRoute.css';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { getPOC3Data } from './data';

const center = { lat: 17.412281, lng: 78.2432311 };

function AddEmpToRoute() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['places'],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [empType, setEmpType] = useState(null);
  const [activeRoute, setRoute] = useState([]);
  const [currentRouteIndex, setCurrentRouteIndex] = useState(-1);
  const [currentEmp, setCurrentEmp] = useState(null);
  const [empIndexInRoute, setEmpIndexInRoute] = useState(1);
  const [assignedEmps, setAssignedEmps] = useState([]);

  useEffect(() => {
    if (activeRoute.length >= 2) {
      calculateRoute();
    }
    return () => {};
  }, [activeRoute]);

  if (!isLoaded) {
    return <div className="p-4">Loading...</div>;
  }

  const EmpsList = () => {
    const UnassignedEmps = getPOC3Data(empType, 'unassignedEmps');
    return UnassignedEmps.map((emp, index) => (
      <li key={emp.Name}>
        <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          <input
            id={`empCheckbox-${index}`}
            type="checkbox"
            disabled={activeRoute.length === 0}
            checked={assignedEmps.includes(emp.Id)}
            onChange={(e) => {
              if (e.target.checked) {
                setCurrentEmp(emp);
              } else {
                removeEmpFromActivRoute(emp.Id);
              }
            }}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            htmlFor="checkbox-item-11"
            className="w-full ms-2 text-sm text-gray-900 rounded dark:text-gray-300"
          >
            {`${emp?.Name} (${emp?.Gender?.toLowerCase()?.at(0)}) | ${
              emp?.Address?.Location
            }`}
          </label>
        </div>
      </li>
    ));
  };

  const RoutesList = () => {
    const Routes = [
      getPOC3Data(empType, 'route1'),
      getPOC3Data(empType, 'route2'),
    ];
    return Routes.map((route, index) => (
      <li key={`route-${index}`}>
        <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          <input
            id={`routeCheckbox-${index}`}
            type="checkbox"
            checked={index === currentRouteIndex}
            value={JSON.stringify(route)} // Using a stable value based on the index
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            onChange={(e) => {
              if (e.target.checked) {
                setCurrentRouteIndex(index);
                setAssignedEmps([]);
                setRoute(route);
              } else {
                setCurrentRouteIndex(-1);
                clearRoute();
              }
            }}
          />
          <label
            htmlFor={`routeCheckbox-${index}`} // Corrected from "for" to "htmlFor"
            className="w-full ms-2 text-sm text-gray-900 rounded dark:text-gray-300"
          >
            <div className="flex justify-start gap-x-1 items-center">
              <p>{`${route?.at(0)?.Address?.Location} to Office`}</p>
              <div className="flex items-center gap-x-0.5">
                <MdOutlineGroup /> {route.length}
              </div>
            </div>
          </label>
        </div>
      </li>
    ));
  };

  const RouteEmpsList = () =>
    activeRoute.map((emp, index) => (
      <li className="ms-8" key={`emp-${index}`}>
        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
          <svg
            className="w-2.5 h-2.5 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
            />
          </svg>
        </span>
        <h3 className="mb-1 text-lg dark:text-white">
          <span className="font-semibold text-white bg-red-500 p-1.5 mr-2 rounded-2xl">
            {String.fromCharCode(65 + index)}
          </span>
          <span>{emp.Name}</span>{' '}
          <span className="text-gray-500 text-sm">({emp.Gender})</span>
        </h3>
        <time className="block mb-3 text-sm font-normal leading-none dark:text-gray-400">
          {emp.Address.Location}
        </time>
      </li>
    ));

  async function calculateRoute() {
    if (activeRoute.length < 2) {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: activeRoute.at(0).Address.GeoCoords,
      destination: activeRoute.at(-1).Address.GeoCoords,
      waypoints: activeRoute.slice(1, -1).map((each) =>
        Object({
          location:
            each.Address.GeoCoords.lat + ',' + each.Address.GeoCoords.lng,
          stopover: true,
        })
      ),

      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setRoute([]);
    map.panTo(center);
    map.setZoom(15);
  }

  const removeEmpFromActivRoute = (empId) => {
    setRoute(activeRoute.filter((each) => each.Id !== empId));
    setAssignedEmps(assignedEmps.filter((e) => e !== empId));
  };
  const addEmpToActivRoute = () => {
    const copyActiveRoutes = JSON.parse(JSON.stringify(activeRoute));
    copyActiveRoutes.splice(empIndexInRoute - 1, 0, currentEmp);
    setEmpIndexInRoute(1);
    setRoute(copyActiveRoutes);
    setAssignedEmps([...assignedEmps, currentEmp.Id]);
    setCurrentEmp(null);
  };

  return (
    <>
      <div
        id="popup-modal"
        tabIndex="-1"
        className={`${
          currentEmp ? 'fixed' : 'hidden'
        } top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
              <h3 className="mb-1 text-lg dark:text-white">
                <span>{currentEmp?.Name}</span>{' '}
                <span className="text-gray-500 text-sm">
                  ({currentEmp?.Gender})
                </span>
              </h3>
              <time className="block mb-3 text-sm font-normal leading-none dark:text-gray-400">
                {currentEmp?.Address?.Location}
              </time>
              <div className="relative flex items-center mb-2">
                <button
                  type="button"
                  id="decrement-button"
                  onClick={() =>
                    empIndexInRoute >= 2 &&
                    setEmpIndexInRoute(empIndexInRoute - 1)
                  }
                  className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                >
                  <svg
                    className="w-3 h-3 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 2"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h16"
                    />
                  </svg>
                </button>
                <input
                  type="text"
                  data-input-counter
                  data-input-counter-min="1"
                  data-input-counter-max={activeRoute.length + 1}
                  className="bg-gray-50 border-x-0 border-gray-300 h-11 font-medium text-center w-full text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block pb-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  value={empIndexInRoute}
                  required
                  onChange={() => null}
                />
                <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/3 flex items-center text-xs text-gray-400 rtl:space-x-reverse">
                  <span>Insertion Position</span>
                </div>
                <button
                  type="button"
                  id="increment-button"
                  onClick={() =>
                    empIndexInRoute < activeRoute.length + 1 &&
                    setEmpIndexInRoute(empIndexInRoute + 1)
                  }
                  className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                >
                  <svg
                    className="w-3 h-3 text-gray-900 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 1v16M1 9h16"
                    />
                  </svg>
                </button>
              </div>
              <button
                type="button"
                id="add-button"
                onClick={() => addEmpToActivRoute()}
                className="text-white hover:text-black ml-2 bg-green-500 dark:bg-purple-500 dark:hover:bg-purple-600 dark:border-purple-600 hover:bg-green-200 border border-black-900 p-2 h-11"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex mx-2"
        style={{
          height: '85vh',
          // border: '5px solid orange',
        }}
      >
        {empType ? (
          <>
            <div className="addemptoroute-section">
              <div className="p-3">
                <label htmlFor="input-group-search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="input-group-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search employees"
                  />
                </div>
              </div>
              <ul
                className="h-48 px-3 pb-3 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownSearchButton"
              >
                <EmpsList />
              </ul>
            </div>
            <div className="addemptoroute-section">
              <div className="p-3">
                <label htmlFor="input-group-search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="input-group-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search routes"
                  />
                </div>
              </div>
              <ul
                className="h-48 px-3 pb-3 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownSearchButton"
              >
                <RoutesList />
              </ul>
            </div>
          </>
        ) : (
          <div className="flex w-48 gap-3">
            <div>
              <input
                type="radio"
                id="option1"
                name="options"
                value="city"
                checked={empType === 'city'}
                onChange={() => setEmpType('city')}
              />
              <label htmlFor="option1">City</label>
            </div>

            <div>
              <input
                type="radio"
                id="option2"
                name="options"
                value="states"
                checked={empType === 'states'}
                onChange={() => setEmpType('states')}
              />
              <label htmlFor="option2">States</label>
            </div>
          </div>
        )}
        {activeRoute?.length >= 2 && (
          <div className="addemptoroute-section p-2">
            <div className="p-4 md:p-5">
              <ol className="relative border-s border-gray-200 dark:border-gray-600 ms-3.5 mb-4 md:mb-5">
                <RouteEmpsList />
              </ol>
            </div>
          </div>
        )}

        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
          onLoad={(map) => setMap(map)}
        >
          {/* <Marker position={center} /> */}
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div>
    </>
  );
}

export default AddEmpToRoute;
