import { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import { useToast } from '../../hooks';
import {
  setCurrentUI,
  setPhoneNumber,
  setAccessToken,
} from '../../redux/slices/globalSlice';
import { sendOTP, verifyOTP } from '../../redux/actions/globalActions';

function Login() {
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [otpRef, setOtpRef] = useState('');
  const [otpValue, setOtpValue] = useState('');

  const { showToast } = useToast();

  const dispatch = useDispatch();

  const sentOtpHandler = () => {
    if (!phoneNumberValue) {
      return showToast('Please enter your phone number', 'error');
    }

    dispatch(sendOTP({ phoneNumber: phoneNumberValue, showToast }))
      .then((res) => {
        const { otpRef } = res.payload;
        setOtpRef(otpRef);
        dispatch(setPhoneNumber(phoneNumberValue));
      })
      .catch((err) => showToast(err, 'error'));
  };

  const otpHandler = (e) => setOtpValue(Number(e.target.value));

  const verifyOtpHandler = () => {
    if (!otpValue) {
      return showToast('Please enter the OTP', 'error');
    }

    dispatch(verifyOTP({ otpRef, otpValue, showToast }))
      .then((res) => {
        const { accessToken } = res.payload;
        dispatch(setAccessToken(accessToken));
        dispatch(setCurrentUI('TA-dashboard'));
      })
      .catch((err) => showToast(err, 'error'));
  };

  return (
    <div className="flex justify-center items-center flex-col h-screen">
      <h1 className="text-2xl mb-4">Login</h1>
      <div className="w-4/5 flex flex-col gap-4 sm:w-3/5 lg:w-[30%]">
        <div>
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumberValue}
            onChange={setPhoneNumberValue}
            defaultCountry="IN"
          />
        </div>

        <button
          onClick={sentOtpHandler}
          className={`py-2 px-3 w-full bg-cyan-800 text-white ${
            otpRef ? 'hidden' : 'block'
          }`}
          type="button"
        >
          Send OTP
        </button>

        {otpRef && (
          <div className="flex flex-col gap-4">
            <input
              placeholder="Enter OTP"
              value={otpValue}
              onChange={otpHandler}
              className="border-[1px] border-slate-500 py-2 px-3 w-full"
            />

            <button
              onClick={verifyOtpHandler}
              className="py-2 px-3 w-full bg-cyan-800 text-white"
              type="button"
            >
              Login
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
