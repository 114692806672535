const Poc3Data = {
  city: {
    unassignedEmps: [
      {
        Id: 1,
        Name: 'Syed',
        Gender: 'Male',
        Address: {
          GeoCoords: { lat: 17.3631837, lng: 78.4934947 },
          Location: 'Dabeerpura',
        },
      },
      {
        Id: 2,
        Name: 'Narsing',
        Gender: 'Female',
        Address: {
          GeoCoords: { lat: 17.3664346, lng: 78.5467402 },
          Location: 'Vasavi Colony',
        },
      },
    ],
    route1: [
      {
        Id: 3,
        Name: 'Ritesh',
        Gender: 'Male',
        Address: {
          GeoCoords: { lat: 17.470599, lng: 78.3651372 },
          Location: 'RTA Office, Kondapur',
        },
      },
      {
        Id: 4,
        Name: 'Nancy',
        Gender: 'Female',
        Address: {
          GeoCoords: { lat: 17.466217, lng: 78.3669352 },
          Location: 'Kims Hospital Kondapur',
        },
      },
    ],
    route2: [
      {
        Id: 5,
        Name: 'Arjuna',
        Gender: 'Male',
        Address: {
          GeoCoords: { lat: 17.4541499, lng: 78.3872299 },
          Location: 'ANVI Fitness',
        },
      },
      {
        Id: 6,
        Name: 'Snigdha',
        Gender: 'Female',
        Address: {
          GeoCoords: { lat: 17.4480437, lng: 78.3880689 },
          Location: "Hasini Woment's PG & Hostel",
        },
      },
      {
        Id: 7,
        Name: 'Vishwas',
        Gender: 'Male',
        Address: {
          GeoCoords: { lat: 17.4516757, lng: 78.3943409 },
          Location: 'BIGSON SERVICE APARTMENTS',
        },
      },
    ],
  },
  states: {
    unassignedEmps: [
      {
        Id: 7,
        Name: 'Manish',
        Gender: 'Male',
        Address: {
          GeoCoords: { lat: 17.4339055, lng: 78.3175642 },
          Location: 'Hyderabad',
        },
      },
      {
        Id: 8,
        Name: 'Sweta',
        Gender: 'Female',
        Address: {
          GeoCoords: { lat: 18.5504585, lng: 73.7510808 },
          Location: 'Pune',
        },
      },
    ],
    route1: [
      {
        Id: 9,
        Name: 'Ritika',
        Gender: 'Female',
        Address: {
          GeoCoords: { lat: 23.0392484, lng: 72.4819465 },
          Location: 'Ahmedabad',
        },
      },
      {
        Id: 10,
        Name: 'Rishabh',
        Gender: 'Male',
        Address: {
          GeoCoords: { lat: 24.6143843, lng: 73.68394 },
          Location: 'Udaipur',
        },
      },
    ],
    route2: [
      {
        Id: 11,
        Name: 'Kashish',
        Gender: 'Female',
        Address: {
          GeoCoords: { lat: 25.4333028, lng: 77.6228488 },
          Location: 'Shivpuri, MP',
        },
      },
      {
        Id: 12,
        Name: 'Akash',
        Gender: 'Male',
        Address: {
          GeoCoords: { lat: 25.3315993, lng: 82.9986676 },
          Location: 'Varanasi, UP',
        },
      },
      {
        Id: 13,
        Name: 'Sabyasachi',
        Gender: 'Female',
        Address: {
          GeoCoords: { lat: 30.728075, lng: 76.7457308 },
          Location: 'Chandigarh',
        },
      },
    ],
  },
};

export const getPOC3Data = (empType, key) => {
  return Poc3Data[empType][key];
};
